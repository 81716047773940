import React from "react";
import { useTable, useSortBy } from "react-table";
import tw, { styled } from "twin.macro";

const TableContainer = styled.div`
  ${tw`w-full`}
`;

const StyledRow = styled.div`
  ${tw`grid grid-cols-1 gap-y-2 p-4 border-b border-gray-300`};
  ${({ isEven }) => (isEven ? tw`bg-gray-100` : tw`bg-white`)}

  @media (min-width: 768px) {
    ${tw`grid-cols-[repeat(auto-fit, minmax(150px, 1fr))] gap-y-0`}
  }
`;

const StyledHeaderRow = styled.div`
  ${tw`hidden md:grid grid-cols-[repeat(auto-fit, minmax(150px, 1fr))] bg-gray-800 text-white font-bold p-4`}
  background-color: ${(props) => props.color};
`;

const StyledHeaderCell = styled.div`
  ${tw`text-sm md:text-base`}
`;

const StyledCell = styled.div`
  ${tw`flex flex-row items-center gap-2`};

  & > span:first-of-type {
    ${tw`font-bold text-gray-700 md:hidden`}
  }

  & > span:last-of-type {
    ${tw`text-sm md:text-base`}
  }
`;

const SortSymbol = styled.span`
  ${tw`text-xs ml-1`}
`;

const Table = ({
  items,
  headers,
  color,
  defaultSortBy = [{ id: "name", desc: false }],
}) => {
  const data = React.useMemo(() => items, []);
  const columns = React.useMemo(() => headers, []);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: defaultSortBy[0].id, // Column accessor
            desc: defaultSortBy[0].desc,
          },
        ],
      },
    },
    useSortBy
  );

  const { headerGroups, rows, prepareRow } = tableInstance;

  return (
    <TableContainer>
      {/* Header Row */}
      {headerGroups.map((headerGroup) => (
        <StyledHeaderRow key={headerGroup.id} color={color}>
          {headerGroup.headers.map((column) => (
            <StyledHeaderCell
              key={column.id}
              {...column.getHeaderProps(column.getSortByToggleProps())}
            >
              {column.render("Header")}
              <SortSymbol>
                {column.isSorted ? (column.isSortedDesc ? " ▲" : " ▼") : ""}
              </SortSymbol>
            </StyledHeaderCell>
          ))}
        </StyledHeaderRow>
      ))}

      {/* Data Rows */}
      {rows.map((row, idx) => {
        prepareRow(row);
        const isEven = idx % 2 === 0;

        return (
          <StyledRow key={row.id} isEven={isEven}>
            {row.cells.map((cell) => (
              <StyledCell key={cell.column.id}>
                <span>{cell.column.Header}:</span>
                <span>{cell.render("Cell")}</span>
              </StyledCell>
            ))}
          </StyledRow>
        );
      })}
    </TableContainer>
  );
};

export default Table;
