import React from "react";
import placeholderImage from "../images/placeholder.jpg";

const AvatarPlaceholder = () => {
  return (
    <img
      src={placeholderImage}
      alt="logo"
      style={{
        width: "auto",
        objectFit: "contain",
      }}
      loading="lazy"
    />
  );
};

export default AvatarPlaceholder;
