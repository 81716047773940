import React from "react";
import _ from "lodash";
import { graphql, Link } from "gatsby";
import tw, { styled } from "twin.macro";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Icon from "@shared/components/Icon";
import Header from "@shared/components/Header";
import TableList from "@shared/components/TableList";
import moment from "moment-timezone";
import { TZ } from "@shared/constants";
import { formatTeamName, MAIN_COLOR, SITE } from "../config";
import AvatarPlaceholder from "../components/AvatarPlaceholder";
import { useSiteFilter } from "../context/SiteFilterContext";

const Container = styled.div`
  ${tw`p-5`}
`;
const Heading = styled.h5`
  ${tw`text-[#EC1F27] font-bold my-4 text-center`}
`;
const PlayerCell = styled.div`
  ${tw`flex items-center`}
`;
const PlayerAvatarContainer = styled.div`
  ${tw`min-w-[40px] w-10 h-10 rounded-full overflow-hidden`}
  & > .material-icons {
    ${tw`text-4xl px-0.5`}
  }
`;
const PlayerName = styled.div`
  ${tw`font-bold`}
`;
const PlayerNumber = styled.div`
  ${tw`opacity-70 ml-2 text-xs`}
`;
const TableTitle = styled.h6`
  ${tw`text-gray-600 font-bold my-4`}
`;
const TableContainer = styled.div`
  ${tw`pb-2 mt-4 min-h-[50px] overflow-x-auto`}
`;
const CoachContainer = styled.div`
  ${tw`p-5`}
`;
const Title = styled.h4`
  ${tw`
    bg-[#EC1F27] text-white font-bold text-center
    p-2.5
  `}
`;
const CoachItem = styled.div`
  ${tw`mt-8 flex flex-col justify-center md:flex-row`}
`;
const AvatarContainer = styled.div`
  ${tw`w-full md:w-[25%]`}
`;
const ContentContainer = styled.div`
  ${tw`w-full md:w-[75%] md:px-4`}
`;
const CoachTitle = styled.h6`
  ${tw`my-2 text-[#535353]`}
`;

const CoachSubtitle = styled.h6`
  ${tw`my-2 text-[#535353] text-sm`}
`;
const StyledLink = styled(Link)`
  ${tw`hover:underline text-[#EC1F27]`}
`;

const ShowSelectButton = styled.div`
  ${tw`text-gray-500 cursor-pointer`}
`;
const HeaderLinkContainer = styled.div`
  ${tw`flex justify-between my-3`}
`;

const ScheduleLink = styled(Link)`
  ${tw`flex items-center text-gray-500`}
`;

const LinkText = styled.span`
  ${tw`underline`}
`;

const headers = (name) => [
  {
    Header: name,
    accessor: "name",
    sortType: "basic",
    width: 200,
    Cell: ({ row }) => {
      // return <StyledLink to={row.original.slug}>{row.values.name}</StyledLink>
      return (
        <PlayerCell>
          {/* <PlayerAvatarContainer>
            {_.isNil(row.original.avatar) ? (
              <Icon name="account_circle" />
            ) : (
              <GatsbyImage
                image={getImage(row.original.avatar)}
                style={{ width: "100%", height: "100%" }}
                imgStyle={{ width: "100%", height: "100%" }}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="avatar"
              />
            )}
          </PlayerAvatarContainer> */}
          <PlayerName>{row.original.name}</PlayerName>
          {/* {!_.isNil(row.original.playerNumber) && (
            <PlayerNumber>{row.original.playerNumber}</PlayerNumber>
          )} */}
        </PlayerCell>
      );
    },
  },
  {
    Header: "Position",
    accessor: (row) => row.positions.map((p) => getPosition(p)).join(", "),
    sortType: "basic",
    width: 183,
  },
  // {
  //   Header: "Home Town",
  //   accessor: "homeTown",
  //   sortType: "basic",
  //   width: 124,
  // },
  {
    Header: "B/T",
    accessor: (row) => `${row.bats[0] || ""}/${row.throws[0] || ""}`,
    sortType: "basic",
    width: 50,
  },
  {
    Header: "College",
    accessor: "college",
    sortType: "basic",
    width: 200,
  },
  // {
  //   Header: "Height",
  //   accessor: "height",
  //   sortType: "basic",
  //   width: 50,
  // },
  // {
  //   Header: "Weight",
  //   accessor: "weight",
  //   sortType: "basic",
  //   width: 50,
  // },
];

// const scheduleHeaders = [
//   {
//     Header: "Date",
//     accessor: (row) => {
//       const slug = `/schedule/${_.kebabCase(row.yr)}/${_.kebabCase(
//         `${row.awayTeam}-${row.homeTeam}`
//       )}-${moment(row.date).tz(TZ).format("YYYY-MM-DD")}${
//         row.numberSuffix ? `-${row.numberSuffix}` : ""
//       }`;
//       return (
//         <StyledLink to={slug}>
//           {moment(row.date).tz(TZ).format("MMM D, YYYY")}
//         </StyledLink>
//       );
//     },
//     sortType: "basic",
//     width: 100,
//   },
//   {
//     Header: "Home",
//     accessor: "homeTeam",
//     sortType: "basic",
//     width: 176,
//   },
//   {
//     Header: "Score",
//     accessor: (row) => {
//       return `${_.isNil(row.homeScore) ? "" : row.homeScore} - ${
//         _.isNil(row.awayScore) ? "" : row.awayScore
//       }`;
//     },
//     sortType: "basic",
//     width: 100,
//   },
//   {
//     Header: "Away",
//     accessor: "awayTeam",
//     sortType: "basic",
//     width: 176,
//   },
//   {
//     Header: "Time",
//     accessor: (row) => moment(row.date).tz(TZ).format("h:mm a"),
//     sortType: "basic",
//     width: 100,
//   },
//   {
//     Header: "Field",
//     accessor: "field",
//     sortType: "basic",
//     width: 157,
//   },
// ];

// const recordHeaders = [
//   {
//     Header: "Team Name",
//     accessor: "name",
//     sortType: "basic",
//     width: 200,
//   },
//   {
//     Header: "W",
//     accessor: "win",
//     sortType: "basic",
//     width: 50,
//   },
//   {
//     Header: "L",
//     accessor: "lose",
//     sortType: "basic",
//     width: 50,
//   },
//   {
//     Header: "Home",
//     accessor: "homeRecord",
//     sortType: "basic",
//     width: 150,
//   },
//   {
//     Header: "Road",
//     accessor: "awayRecord",
//     sortType: "basic",
//     width: 150,
//   },
//   {
//     Header: "Strk",
//     accessor: "streak",
//     sortType: "basic",
//     width: 100,
//   },
// ];

const RosterPage = ({ data, pageContext, location }) => {
  // const { homeTeam: currentHomeTeam, setHomeTeam } = useSiteFilter();
  const teamName = pageContext.name;
  const { seo, hero, tabs, title, coachSectionTitle } =
    data.markdownRemark.frontmatter;

  const coaches = data.coachMds.edges;
  // roster
  const players = data.playerMds.edges.map((edge) => {
    const {
      // avatar,
      playerNumber,
      name,
      weight,
      height,
      team,
      yr,
      positions,
      college,
      bats,
      throws,
      homeTown,
      alumni,
    } = edge.node.frontmatter;
    const slug = edge.node.fields.slug;
    return {
      // avatar,
      playerNumber,
      name,
      weight,
      height,
      team,
      yr,
      positions,
      college,
      bats,
      throws,
      homeTown,
      slug,
      alumni,
    };
  });
  const teams = data.teams.edges;

  const selectedTeam = (teams || []).find(
    (t) => t.node.frontmatter.name === teamName
  );
  const selectedTeamHero = _.get(selectedTeam, "node.frontmatter.hero");
  const momentCurrDate = moment.tz(new Date(), TZ);
  return (
    <Layout
      seoTitle={seo?.title || title}
      seoDescription={seo?.description}
      location={location}
    >
      <Container>
        <Title>{`Dawgs ${teamName}`}</Title>
        <HeaderLinkContainer>
          <ScheduleLink
            to={`/schedule?team=${teamName.toLowerCase().replace(" ", "-")}`}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Icon name="keyboard_backspace" />
            <LinkText>{teamName} Schedule</LinkText>
          </ScheduleLink>
          {/* <ShowSelectButton
            onClick={() => setHomeTeam("", location)}
            style={{ display: "flex", alignItems: "center" }}
          >
            <LinkText>Change Team</LinkText>
            <Icon name="arrow_upward" />
          </ShowSelectButton> */}
        </HeaderLinkContainer>
        <Header
          heroData={selectedTeamHero || hero}
          tabsData={tabs}
          color={MAIN_COLOR}
          site={SITE}
        />

        {/* <Heading>{teamName} ROSTER</Heading> */}

        {/* <TableTitle>Pitchers</TableTitle> */}
        <TableContainer>
          <TableList
            items={players}
            headers={headers("Name")}
            color={MAIN_COLOR}
          />
        </TableContainer>
      </Container>
      {/* Coaches */}
      {!_.isEmpty(coaches) && (
        <CoachContainer>
          <Title>{coachSectionTitle}</Title>
          {coaches
            .sort((a, b) => a.node.frontmatter.order - b.node.frontmatter.order)
            .map((coach) => {
              const { id, html } = coach.node;
              const { name, avatar, title, college, homeTown } =
                coach.node.frontmatter;
              const img = getImage(avatar);
              return (
                <CoachItem key={id}>
                  <AvatarContainer>
                    {img ? (
                      <GatsbyImage
                        image={img}
                        alt="avatar"
                        style={{ width: "100%", height: "100%" }}
                        imgStyle={{ width: "100%", height: "100%" }}
                        objectFit="cover"
                        objectPosition="50% 50%"
                      />
                    ) : (
                      <AvatarPlaceholder />
                    )}
                  </AvatarContainer>
                  <ContentContainer>
                    <CoachTitle>
                      {name} - {title}
                    </CoachTitle>
                    <CoachSubtitle>
                      {college} {homeTown}
                    </CoachSubtitle>
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  </ContentContainer>
                </CoachItem>
              );
            })}
        </CoachContainer>
      )}
    </Layout>
  );
};

export default RosterPage;

export const pageQuery = graphql`
  query RosterPageQuery($name: String) {
    markdownRemark(frontmatter: { templateKey: { eq: "team-list-page" } }) {
      frontmatter {
        seo {
          title
          description
        }
        hero {
          pageImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          pageImagePosition
        }
        title
        coachSectionTitle
      }
    }
    playerMds: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "player-page" }
          team: { eq: $name }
          # alumni: { ne: true }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            # avatar {
            #   childImageSharp {
            #     gatsbyImageData(layout: FULL_WIDTH)
            #   }
            # }
            # playerNumber
            # age
            name
            # weight
            # height
            team
            # yr
            positions
            college
            bats
            throws
            homeTown
            alumni
          }
        }
      }
    }
    coachMds: allMarkdownRemark(
      filter: { frontmatter: { mdType: { eq: "coach" }, team: { eq: $name } } }
    ) {
      edges {
        node {
          id
          html
          fields {
            slug
          }
          frontmatter {
            name
            avatar {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            title
            yr
            college
            homeTown
            order
          }
        }
      }
    }
    teams: allMarkdownRemark(
      filter: {
        frontmatter: { mdType: { eq: "team" }, createPage: { eq: true } }
      }
    ) {
      edges {
        node {
          frontmatter {
            name
            hero {
              pageImage {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              pageImagePosition
            }
          }
        }
      }
    }
  }
`;

const getPosition = (position) => {
  let term = "";
  switch (position) {
    case "Right-handed Pitcher":
      term = "RHP";
      break;
    case "Left-handed Pitcher":
      term = "LHP";
      break;
    case "Catcher":
      term = "C";
      break;
    case "Infield":
      term = "INF";
      break;
    case "Outfield":
      term = "OF";
      break;
    default:
      break;
  }
  return term;
};

const getTeamRecordFromGames = (teamName, games) => {
  let tracker = {
    name: teamName,
    win: 0,
    lose: 0,
    homeWin: 0,
    homeLose: 0,
    homeDraw: 0,
    awayWin: 0,
    awayLose: 0,
    awayDraw: 0,
    streakType: "win",
    streakCount: 0,
  };

  for (let i = 0; i < games.length; i++) {
    const { homeTeam, homeScore, awayScore } = games[i];
    const isHomeGame = homeTeam === teamName;
    let gameResult;
    if (!_.isNil(homeScore) && !_.isNil(awayScore)) {
      if (homeScore > awayScore) {
        gameResult = isHomeGame ? "win" : "lose";
      } else if (homeScore < awayScore) {
        gameResult = isHomeGame ? "lose" : "win";
      } else {
        gameResult = "draw";
      }
    }
    if (_.isNil(gameResult)) {
      continue;
    } else {
      tracker.win += gameResult === "win" ? 1 : 0;
      tracker.lose += gameResult === "lose" ? 1 : 0;
      tracker.homeWin += gameResult === "win" && isHomeGame ? 1 : 0;
      tracker.homeLose += gameResult === "lose" && isHomeGame ? 1 : 0;
      tracker.homeDraw += gameResult === "draw" && isHomeGame ? 1 : 0;
      tracker.awayWin += gameResult === "win" && !isHomeGame ? 1 : 0;
      tracker.awayLose += gameResult === "lose" && !isHomeGame ? 1 : 0;
      tracker.awayDraw += gameResult === "draw" && !isHomeGame ? 1 : 0;

      if (gameResult === tracker.streakType) {
        tracker.streakCount += 1;
      } else {
        tracker.streakCount = 1;
        tracker.streakType = gameResult;
      }
    }
  }

  return {
    name: tracker.name,
    win: tracker.win,
    lose: tracker.lose,
    homeRecord: `${tracker.homeWin}-${tracker.homeLose}-${tracker.homeDraw}`,
    awayRecord: `${tracker.awayWin}-${tracker.awayLose}-${tracker.awayDraw}`,
    streak: `${_.upperCase(tracker.streakType[0])}${tracker.streakCount}`,
  };
};
